import { useMemo, useEffect, useState, JSX } from "react";
import { Heading } from "@otrium/atoms";
import { Box } from "@otrium/core";
import { msg, Trans } from "@lingui/macro";
import { FilterList, Item as FilterItem } from "src/atoms/FilterList";
import {
  HomepageDepartmentSection,
  HomepageShopSection,
  HomePageSingleBrand,
} from "src/types/graphql.d";
import { BrandCarousel } from "src/molecules/BrandCarousel";
import { ContentMobileFullscreen, Content } from "src/atoms/Content";
import { SEGMENT_PROMOTION_TYPE } from "src/segment";
import { MessageDescriptor } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { LoginRegisterModal } from "src/molecules/LoginRegisterModal";

interface Props {
  shops: HomepageShopSection[];
  department?: HomepageDepartmentSection;
  genderId: string;
  hideTitle?: boolean;
}

interface CarouselData {
  title: string;
  type: SupportedKeys;
  data: HomePageSingleBrand[];
}

/**
 * The order of this array is used to sort the filters
 */
export const SUPPORTED_KEYS = [
  "just_arrived_shops",
  "popular_shops",
  "favourited_shops",
  "department_designer",
  "conscious_shops",
] as const;

export type SupportedKeys = (typeof SUPPORTED_KEYS)[number];

const translatedTitles: Record<SupportedKeys, MessageDescriptor> = {
  ["popular_shops"]: msg`Most popular`,
  ["favourited_shops"]: msg`For you`,
  ["department_designer"]: msg`Designer`,
  ["just_arrived_shops"]: msg`New Items`,
  ["conscious_shops"]: msg`Ethically rated`,
};

const SEGMENT_PROMOTION: Record<SupportedKeys, SEGMENT_PROMOTION_TYPE> = {
  popular_shops: SEGMENT_PROMOTION_TYPE.popularShops,
  favourited_shops: SEGMENT_PROMOTION_TYPE.favouritedShops,
  department_designer: SEGMENT_PROMOTION_TYPE.departmentDesigner,
  just_arrived_shops: SEGMENT_PROMOTION_TYPE.justArrivedShops,
  conscious_shops: SEGMENT_PROMOTION_TYPE.consciousShops,
};

const ExploreMoreCarousel = ({
  shops,
  department,
  genderId,
  hideTitle,
}: Props): JSX.Element | null => {
  const { _ } = useLingui();
  const isLoggedIn = useIsLoggedIn();
  const currentUrl = window.location.href;
  const [activeSection, setActiveSection] = useState<string>(SUPPORTED_KEYS[1]);
  const [open, setOpen] = useState<boolean>(false);

  const carouselData: CarouselData[] = useMemo(() => {
    const newData: CarouselData[] = [];

    const availableShops = shops
      .filter(
        (shop) =>
          shop.data.length >= 3 &&
          SUPPORTED_KEYS.includes(shop.type as SupportedKeys)
      )
      .map((shop) => ({
        title: shop.title ?? "",
        data: shop.data,
        type: shop.type as SupportedKeys,
      }));

    newData.push(...availableShops);

    if (
      department &&
      department.data.brands &&
      department.data.brands?.length >= 3
    ) {
      newData.push({
        title: department.data.department_title,
        data: department.data.brands,
        type: department.type as SupportedKeys,
      });
    }

    newData.sort(
      (a, b) => SUPPORTED_KEYS.indexOf(a.type) - SUPPORTED_KEYS.indexOf(b.type)
    );

    return newData;
  }, [shops, department]);

  const filterData: FilterItem[] = useMemo(() => {
    return carouselData.map((item) => {
      if (!isLoggedIn && item.type === "favourited_shops") {
        return {
          key: item.type,
          content: _(msg`Log in to access`),
          active: item.type === activeSection,
          onClick: (key) => {
            setOpen(true);
            setActiveSection(key);
          },
        };
      }
      return {
        key: item.type,
        content: _(translatedTitles[item.type]),
        active: item.type === activeSection,
        onClick: (key) => setActiveSection(key),
      };
    });
  }, [carouselData, activeSection, _, isLoggedIn]);

  useEffect(() => {
    const [item] = carouselData;
    setActiveSection((section) => section || (item?.type ?? ""));
  }, [carouselData]);

  const currentSection = carouselData.find(
    (item) => item.type === activeSection
  );

  if (!currentSection) return null;

  return (
    <section>
      <Content>
        {!hideTitle && (
          <Heading
            as="h2"
            fontFamily="CharisSILW"
            fontSize={[2, 2, 2, 3]}
            mb={3}
            color="Black"
            sx={{
              lineHeight: 1.25,
              textTransform: "none",
            }}
          >
            <Trans>Explore more</Trans>
          </Heading>
        )}

        <Box mb={4} sx={{ position: "relative", zIndex: 2 }}>
          <FilterList items={filterData} />
        </Box>
      </Content>

      <ContentMobileFullscreen>
        <BrandCarousel
          key={activeSection} // key added intentionally. Forces remounting on the component on activeSection change
          genderId={genderId}
          brands={currentSection.data}
          slidesDesktop={4}
          slidesTablet={3}
          slidesMobile={1.5}
          tab={activeSection}
          promotionType={SEGMENT_PROMOTION[activeSection as SupportedKeys]}
          pageName="homepage"
        />
      </ContentMobileFullscreen>
      {open && (
        <LoginRegisterModal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          redirectTo={currentUrl}
          trackingData={{
            pageName: "homepage",
            shopType: genderId,
            actionSource: "feature_brand_tab",
            redirectTo: currentUrl,
          }}
        />
      )}
    </section>
  );
};

export default ExploreMoreCarousel;
